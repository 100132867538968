<template>
    <MyBases />
</template>

<script>
import pageMeta from '@/mixins/pageMeta'
export default {
    mixins: [pageMeta],
    components: {
        MyBases: () => import('@apps/MyBases')
    }
}
</script>
